/* You can add global styles to this file, and also import other style files */

/* === Toastr === */
@import '~ngx-toastr/toastr.css';

.pointer {
    cursor: pointer !important;
}

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2.5rem;            /* Footer height */
}

.pageItems {
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    height: fit-content;
    margin-left: 1%;
}

.ngx-pagination .current {
    background: linear-gradient(to right, #cd8dd3, #02D814) !important;
}

thead > tr > th {
    font-weight: bold !important;
}

.w-40 {
    width: 40% !important;
}

.invalid {
    border-color: red !important;
}

.az-content-body.pd-lg-l-40.d-flex {
    width: 95% !important;
}
